import React from 'react';
import ProjectCard from './ProjectCard';

const ProjectSection = ({ title, projects }) => {
  return (
    <section className="project-section">
      <h2>{title}</h2>
      <div className="project-grid">
        {projects.map((project, index) => (
          <ProjectCard
            key={index}
            name={project.name}
            description={project.description}
            link={project.link}
            screenshot = {project.screenshot}
            video = {project.video}
          />
        ))}
      </div>
    </section>
  );
};

export default ProjectSection;
