import React from 'react';

const ProjectCard = ({ name, description, link, screenshot, video }) => {
  return (
    <div className="project-card">
      <h3>{name}</h3>
      <p>{description}</p>
      
      {/* Add Screenshot */}
      {screenshot && (
        <img src={screenshot} alt={`${name} screenshot`} className="project-screenshot" />
      )}

      {/* Embed Video */}
      {video && (
        <div className="video-wrapper">
          <iframe
            width="100%"
            height="200"
            src={video}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title={`${name} video`}
          ></iframe>
        </div>
      )}

      <a href={link} target="_blank" rel="noopener noreferrer">
        View Project
      </a>
    </div>
  );
};

export default ProjectCard;
