import React from 'react';

const Header = () => {
  return (
    <header className="header">
      <h1>Tim Ng'uono - Portfolio</h1>
      <p>Mobile App Developer</p>
    </header>
  );
};

export default Header;
